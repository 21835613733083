/* eslint-disable import/order */

const React = require('react');
const PropTypes = require('prop-types');
const serialize = require('serialize-javascript');

const { Head } = require('nordic/head');
const { Script } = require('nordic/script');
const { MelidataTrack } = require('nordic/melidata/melidata-track');

const Gtm = require('../gtm');
const QaMessage = require('../qa-message');
const ScriptsHead = require('../scripts-head');

const useDataDogRum = require('@hooks/use-datadog-rum');

const { DESCRIPTION, KEYWORDS, TITLE } = require('@domain/constants/seo');
const GTM = require('@domain/constants/gtm');

const Commons = (props) => {
  const { datadog, name, siteId = 'MLA' } = props;
  const preloadedState = { ...props };

  if (typeof window !== 'undefined' && window.MobileWebKit && window.MobileWebKit.topbar) {
    window.MobileWebKit.topbar.setup({
      bar_title: siteId === 'MLB' ? 'Programa de Revenda' : 'Revendedores Point',
    });
  }

  useDataDogRum(datadog);

  return (
    <>
      <Head>
        <ScriptsHead />
        <meta content="Mercado Pago" name="author" />
        <meta content={TITLE[siteId]} property="og:title" />
        <meta content={DESCRIPTION[siteId]} name="description" />
        <meta content={DESCRIPTION[siteId]} property="og:description" />
        {KEYWORDS[siteId] && <meta content={KEYWORDS[siteId]} name="keywords" />}
        <title>{TITLE[siteId]}</title>
      </Head>

      <MelidataTrack
        application={{
          site_id: siteId,
        }}
        client={{ department: 'mpos' }}
        path={`/merchant_acquisition/flows/resellers/landing/${name?.toLowerCase()}`}
      />

      <Gtm id={GTM[siteId]} siteId={siteId} />

      <Script>
        {`window.__PRELOADED_STATE__ = ${serialize(preloadedState, { isJSON: true })};`}
      </Script>
      <Script src="vendor.js" />

      <QaMessage siteId={siteId} />
    </>
  );
};

Commons.propTypes = {
  datadog: PropTypes.shape({
    userId: PropTypes.number,
    version: PropTypes.string,
    service: PropTypes.string,
    applicationId: PropTypes.string,
    clientToken: PropTypes.string,
    env: PropTypes.string,
  }),
  name: PropTypes.string,
  siteId: PropTypes.string.isRequired,
};

module.exports = Commons;
